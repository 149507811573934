import './style.css'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls.js'
import { CubeRefractionMapping, Vector3 } from 'three'

/**
 * Base
 */




//Cursour

const Cursour ={
    x: 0,
    y: 0
}
// window.addEventListener('touchmove', (event) =>
// {
//     Cursour.x = event.touches[0].clientX /sizes.width - 0.5
//     Cursour.y = -(event.touches[0].clientY  / sizes.height -0.5)
// })
// window.addEventListener('mousemove', (event) =>
// {
//     Cursour.x = event.clientX /sizes.width - 0.5
//     Cursour.y = -(event.clientY  / sizes.height -0.5)
// })
// Canvas
const canvas = document.querySelector('canvas.webgl')



// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize',() => 
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))

    
})

window.addEventListener('dblclick', () =>
{
    if (!document.fullscreenElement)
    {
        canvas.requestFullscreen()
    }
    else
    {
        document.exitFullscreen()
    }
})

// Scene
const scene = new THREE.Scene()

//Axis Helper
// const axisHelper = new THREE.AxesHelper(20)
// scene.add(axisHelper)

// Object
/*=================CUSTOM GEOMETRY TO DRAW CUBE=================
const geometry = new THREE.Geometry()

const vertex1 = new THREE.Vector3 (0,0,0)
geometry.vertices.push(vertex1)

const vertex2 = new THREE.Vector3 (0,1,0)
geometry.vertices.push(vertex2)

const vertex3 = new THREE.Vector3 (1,0,0)
geometry.vertices.push(vertex3)

const vertex4 = new Vector3 (1,1,0)
geometry.vertices.push(vertex4)                                                                                                                                                                                                                                                                                                      

const vertex5 = new Vector3 (0,0,1)
geometry.vertices.push(vertex5)

const vertex6 = new Vector3 (0,1,1)
geometry.vertices.push(vertex6)

const vertex7 = new Vector3 (1,0,1)
geometry.vertices.push(vertex7)

const vertex8 = new Vector3 (1,1,1)
geometry.vertices.push(vertex8)

let face = new THREE.Face3(0,2,1)
geometry.faces.push(face)

face = new THREE.Face3(2,1,3)
geometry.faces.push(face)

face = new THREE.Face3(0,4,5)
geometry.faces.push(face)

face = new THREE.Face3(0,5,1)
geometry.faces.push(face)

face = new THREE.Face3(0,4,6)
geometry.faces.push(face)

face = new THREE.Face3(0,6,2)
geometry.faces.push(face)

face = new THREE.Face3(4,6,7)
geometry.faces.push(face)

face = new THREE.Face3(4,7,5)
geometry.faces.push(face)

face = new THREE.Face3(6,2,7)
geometry.faces.push(face)

face = new THREE.Face3(2,7,3)
geometry.faces.push(face)

face = new THREE.Face3(7,5,3)
geometry.faces.push(face)

face = new THREE.Face3(3,5,1)
geometry.faces.push(face)

====================================================================*/

/*=======================BUFFER GEOMETRY EXAMPLE========================
const geometry = new THREE.BufferGeometry();
// create a simple square shape. We duplicate the top left and bottom right
// vertices because each vertex needs to appear once per triangle.
const vertices = new Float32Array( [
	-1.0, -1.0,  1.0,
	 1.0, -1.0,  1.0,
	 1.0,  1.0,  1.0,

	 1.0,  1.0,  1.0,
	-1.0,  1.0,  1.0,
	-1.0, -1.0,  1.0
] );

// itemSize = 3 because there are 3 values (components) per vertex
geometry.setAttribute('position',new THREE.BufferAttribute(vertices, 3))
========================================================================*/

const geometry = new THREE.BoxGeometry(1,1,1,2,2,2)
const material = new THREE.MeshBasicMaterial({ 
        color: 0xff0000,
        wireframe: true,      
        wireframeLinewidth: 3.0
    })
const mesh = new THREE.Mesh(geometry,material)
scene.add(mesh)

// const geometry = new THREE.TorusGeometry( 10, 3, 16, 100 );
// const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
// const mesh = new THREE.Mesh( geometry, material );
// scene.add( mesh );




// Camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height)
// camera.position.x = 2
// camera.position.y = 2
camera.position.z = 3
camera.lookAt(mesh.position)
scene.add(camera)

// Renderer~~
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))



//OrbitControl
const controls = new TrackballControls(camera, canvas)
controls.enableDamping = true
controls.rotateSpeed = 2.0
controls.update()

// Animate
const clock = new THREE.Clock()


const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update objects
    // mesh.rotation.y = elapsedTime;
    // camera.position.x=Math.sin(Cursour.x * Math.PI*2)*3
    // camera.position.z=Math.cos(Cursour.x * Math.PI * 2)*3
    // camera.position.y=Cursour.y * 5
    
    // camera.lookAt(mesh.position)
    mesh.rotation.y = elapsedTime * Math.PI/2

    // Render
    renderer.render(scene, camera)
    controls.update()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()